<template>
  <div class="sub-item">
    <!-- <i :class="[icon, 'sub-el-icon']" /> -->
    <img :src="path" class="sub-el-icon">
    <span class="sub-title">{{ $t(title) }}</span>
    <img class="ml-auto mr-5 h-4 w-4 opacity-0 duration-base group-hover:mr-0 group-hover:opacity-100" src="https://sky-win.cc/img/arrow-right-1b4c6797.svg" alt="arrow-right" data-v-318578d1="">
  </div>
</template>
<script>

export default {
  name: 'MenuItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    other:{
      type:Boolean,
      default:false
    }
  },
  computed: {
    path() {
      if(this.other){
        return this.icon
      }
      if (this.$route.fullPath === this.link) {
        return require('@/assets/v3/nav/' + this.icon + '_h.png')
      }
      return require('@/assets/v3/nav/' + this.icon + '.png')
    }
  }
}
</script>

<style scoped>
.sub-item{
  display: flex;
  text-align: center;
align-items: center;
width: 10rem;
}
.sub-el-icon {
  color: currentColor;
  width: 26px;
  height: 26px;
}
.sub-title{
  width:60px;
  display: inline-block;
  text-align: left;
}
</style>
