<template>

  <section class="app-main px-[15px]  md:px-[30px] xl:px-[70px] md:pt-[25px] md:pb-[60px] xl:pb-20 px-0 4xl:ml-[300px]">
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <component :is="Component" :key="key" />
        <!-- <keep-alive :include="cachedViews">
        </keep-alive> -->
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      // return this.$store.state.tagsView.cachedViews
      return ''
    },
    key() {
      // console.log('path', this.$route.path)
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/variables.scss";
.app-main {
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  // overflow: hidden;
  background-color: $bgColor;
}

.fixed-header+.app-main {
  padding-top: 50px;
}

.hasTagsView {
  .fixed-header+.app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
