<template>
  <div
    :id="id"
    class="profile-menu-item bg-second-info rounded-normal mb-[15px] md:pb-[15px] w-full md:w-1/2 md:odd:mr-2.5"
  >
    <div
      class="px-[15px] py-3 md:py-[15px] text-second-layout-tx border-b border-solid border-input-default-bd"
    >
      {{title}}
    </div>
<slot></slot>
  </div>
</template>
<script>
export default {
  name: "noData",
  props: {
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    
  },
};
</script>
<style lang="scss" scoped>
  .text-second-layout-tx {
    text-align: start;
  }
</style>
