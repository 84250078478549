export default {
  common: {
    confirm: "确定",
    cancel: "取消",
    apply: "提交",
    pleaseInput: "请输入{N}",
    totalsome: "共 {N}",
    norecord: "暂无记录",
    game: "游戏",
    keywords: "关键字",
    favourite: "猜你喜欢",
    select: "选择{N}",
    closeTip: "确认退出游戏?",
    switch: "切换{N}",
    wallet: "钱包",
    lastDays: "最近{N}天",
    billTypeStr: "提示",
    time: "{N}时间",
  },
  navs: {
    game: "娱乐城首页",
    slot: "娱乐城",
    search: "搜索",
    gameRecord: "游戏记录",
  },
  game: {
    gameNavs: "游戏分类",
    gameElectronic: "电子游戏",
    gameElectronicDesc: "各式各样风格的老虎机游戏，让您任挑任选",
    gameVideo: "真人视讯",
    gameTicket: "捕鱼游戏",
    gameChess: "棋牌游戏",
    gameSport: "体育赛事",
    blockchain: "区块链",
    gameRecommand: "游戏推荐",
    gameNew: "最新游戏",
    gameRecentBet: "近期投注",
    gameMonthList: "月爆奖热榜",
    gameName: "游戏名称",
    gameUser: "玩家",
    gametime: "时间",
    gameBet: "投注",
    gameRate: "赔率",
    gameWin: "盈利",
  },
  playConfirm: {
    gameBlance: "游戏余额",
    recharge: "充值",
    transferIn: "转入",
    transferOut: "转出",
    start: "开始游戏",
    diamondBalance: "游戏可用余额",
    avaliableBlance: "游戏可用余额",
    usdtRate: "USDT汇率",
    avaliableWithdrawBlance: "可转入钻石余额",
    supportBrowser: "支持浏览器",
    supportPlatform: "支持平台",
    supportLanguage: "支持语言",
    transferInUsdt: "预计转入",
    transferOutUsdt: "预计转出",
    demo: "试玩"
  },
  gameList: {
    filter: "筛选",
    tag: "游戏标签",
    provider: "游戏厂家",
  },
  gameRecord: {
    order: "单号",
    winAmount: "盈利",
    betAmount: "投注金额",
  },
};
