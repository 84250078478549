import client from '@/api/client.js'
import { setToken, removeToken } from '@/util/auth'

const state = {
  token: '',
  userInfo: {},
  gameInfo: {},
  avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
  appInfo: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_APPINFO: (state, info) => {
    state.appInfo = info
  },
  SET_GAMEINFO: (state, gameInfo) => {
    state.gameInfo = gameInfo
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      client.login(userInfo).then(response => {
        if (response) {
          commit('SET_TOKEN', response)
          setToken(response)
          resolve(response)
        } else {
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      client.userinfo().then(response => {
        if (response) {
          commit('SET_USERINFO', response)
          resolve(response)
        } else {
          removeToken()
          reject('token is error')
        }
      }).catch(error => {
        removeToken()
        reject(error)
      })
    })
  },
  // get user info
  getAppInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      client.appInfo().then(response => {
        console.log('appinfo', response)
        commit('SET_APPINFO', response)
        resolve(response)
      }).catch(error => {
        removeToken()
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      client.logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_USERINFO', {})
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  getGameInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      client.getGameInfo().then((res) => {
        commit('SET_GAMEINFO', res)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_USERINFO', {})
      removeToken()
      resolve()
    })
  },
  setToken({ commit }, token) {
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      setToken(token)
      resolve()
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
