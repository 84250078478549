<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <!-- <sidebar class="sidebar-container " /> -->
    <el-scrollbar wrap-class="scrollbar-wrapper" :style="{ 'padding-bottom': device === 'mobile' ? '0px' : '0' }">
      <div :class="{ hasTagsView: needTagsView }" class="main-container">
        <div :class="{ 'fixed-header': fixedHeader }">
          <van-sticky>
            <navbar >
              <template #right v-if="currentpage==='home' || currentpage==='playconfirm'">
                <div class="h-full pr-2 flex items-center">
                <el-dropdown trigger="click" >
                  <div class="flex h-full items-center py-4">
                    <yu-select :title="defaultWallet.walletBalance" @refresh="getWallet"> </yu-select>
                  </div>
                  <template #dropdown>
                    <div class="mx-4">
                      <div class="text-start text-base text-white blod m-2 mb-3">{{$t('common.switch',{N:$t('common.wallet')})}}</div>
                      <div class=" w-[220px] flex  flex-col text-white" >
                        <div v-for="(item,index) in wallets" :key="index" class="p-2  flex flex-row justify-between text-sm" :class="{'text-yellow-400':item.walletName===defaultWallet.walletName}" @click="select(item)">
                        <div class="flex flex-row justify-between">
                          <img v-show="item.walletType==='USDT'" class="w-[20px]" style="vertical-align:middle;margin-right:2px;" src="../assets/v5/USDT.png"/>
                          <img v-show="item.walletType==='INR'" class="w-[20px]" style="vertical-align:middle;margin-right:2px;" src="../assets/v5/diamone_coin.png"/> {{ item.walletName }}</div>
                        <div class="flex flex-row">
                          <div>{{ item.walletBalance }}</div>
                          <div v-if="item.walletProvider!==''"> / {{ item.withdrawBalance }}</div>
                        </div>
                      </div>
                    </div>
                    <yu-button v-if="defaultWallet.showRechargeButton" @click="openRecharge()" class="text-xs mb-4">
                      <span class="d-button-slot"
                      ><div class="flex items-center">{{ $t('playConfirm.recharge') }}</div></span
                      >
                    </yu-button>

                      </div>
                  </template>
                </el-dropdown>
              </div>
                </template>
              </navbar>
          </van-sticky>
        </div>
        <app-main />
      </div>
    </el-scrollbar>
    <van-popup v-model:show="search" closeable position="bottom" @close="toggleSearch" :style="{ height: '100%' }" class="bg-[#1d2841]">
      <Search></Search>
    </van-popup>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar, Search } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    Sidebar,
    Search,
  },
  mixins: [ResizeMixin],
  data() {
    return {
      value: "",
      currentpage:'',
      defaultWallet:{walletName:'',walletBalance:''},
      wallets:[
        {
          walletBalance: 43,walletName: "OB SLOT",walletProvider: "OB_SLOT",withdrawBalance: 0
        }
      ],
    };
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      search: (state) => state.app.search.open,
      showSettings: (state) => state.settings.showSettings,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  watch: {
    $route:{
      immediate:true,
      handler:function(route) {
        this.currentpage = ''
        if (route.path==='/'||route.path==='/game') {
          this.currentpage = 'home'
          return
        }
        if (route.path==='/playconfirm') {
          this.currentpage = 'playconfirm'
          return
        }
      }

    }
  },
  mounted() { 
    this.getWallet()
  },
  methods: {
    getWallet(){
      this.$client.GetWalletList().then(res=>{
        if(!res||res.length===0){
          return
        }
        this.wallets = res.map((e)=>{
          return {
            ...e,
            walletBalance: Number(e.walletBalance).toFixed(2),
            withdrawBalance: Number(e.withdrawBalance).toFixed(2)
          }
        })
        var exist = this.wallets.find((e)=>(e.walletName===this.defaultWallet.walletName))
        if(exist){
          this.defaultWallet = exist;
        }else{
          this.defaultWallet = this.wallets[0]
        }
      })
    },
    select(e){
      this.defaultWallet = e
    },
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    toggleSearch() {
      this.$store.dispatch("app/toggleSearch",false);
    },
    openRecharge(){
      if(window['FoxApp']){
            console.log('is app is appis appis appis appis app',FoxApp)
            FoxApp.postMessage(JSON.stringify({type:'recharge',gameId:this.defaultWallet.walletProvider}));
        }else{
          console.log('is h5 is h5is h5is h5is h5is h5is h5is h5is h5')
          window.parent.postMessage(JSON.stringify({type:'recharge',gameId:this.defaultWallet.walletProvider}),'*');
        }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}


</style>
