<template>
  <div
    id="views_profile_index_team_share"
    class="group px-[15px] py-2.5 flex justify-between items-center cursor-pointer md:hover:bg-white/10 md:hover:text-active md:mt-5"
  >
    <div class="flex items-center w-full">
      <img class="w-4 h-4 md:w-5 md:h-5 mr-2 md:mr-2.5" :src="icon" />
      <div>{{ title }}</div>
      <img
        class="hidden md:inline-block mr-5 h-4 w-4 opacity-0 duration-base md:group-hover:mr-0 md:group-hover:opacity-100 ml-auto"
        src="https://sky-win.cc/img/arrow-right-active-9f5f37fa.svg"
        alt="arrow-right"
      />
    </div>
    <img
      class="w-3 h-3 md:hidden"
      src="https://sky-win.cc/img/arrow-right-1b4c6797.svg"
      alt="arrow-right"
    />
  </div>
</template>
<script>
export default {
  name: "noData",
  props: {
    title: {
      type: String,
      default: "没有符合的结果",
    },
    icon: {
      type: String,
      default: "https://sky-win.cc/img/icon-share-c66eef35.svg",
    },
  },
};
</script>
