<template>
    <div class="w-full" style="/* display: none; */">
          <div
            class="flex justify-between py-2.5 border-b border-solid border-layout-bd"
          >
            <div class="flex items-center">
              <img
                :src="icon"
                alt=""
                class="mr-2.5 w-[18px] md:w-5 h-[18px] md:h-5"
              />
              <div class="md:text-lg">{{title}}</div>
            </div>
          </div>
          <slot/>
        </div>
    </template>
    <script>
    export default{
        props:{
            title:{
                type:String,
                default:''
            },
            icon:{
                type:String,
                default:'https://sky-win.cc/img/icon-game-history-active-ebc92d97.svg'
            }
        }
    }
</script>