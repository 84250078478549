<template>
    <div class="h-10 mt-4 text-[#fff] text-start items-center flex">
      <span class="ml-6  text-xl">
        {{$t('navs.search')}}
      </span>
    </div>
    <van-search background="transparent" v-model="value" :placeholder="$t('common.pleaseInput',{N:$t('game.keywords')})" />
  <div class="pt-1 px-4 text-[#fff]">
    <div
      class="h-full w-full max-w-[1290px] pb-5 overflow-y-scroll overscroll-none bg-modal" @click="startGame()"
    >
      <div v-if="gameList.length==0"
        class="flex flex-col items-center justify-center max-w-[1230px] h-[200px] md:h-[313px] m-auto"
      >
        <img
          src="../../assets/v5/no-game-2a506ace.svg"
          alt="no game"
          class="w-20 h-20 md:w-[120px] md:h-[120px]"
          draggable="false"
        />
        <div class="text-addition">{{$t('common.norecord')}}</div>
      </div>
      <div class="max-w-[1230px] m-auto" v-if="gameList.length>0">
        <Tag :title="$t('common.game')">
          <div
            class="flex w-full py-[15px] overflow-x-auto overflow-hidden scroll-smooth"
          >
            <div class="flex">
              <div class="flex flex-col items-center last:mr-0 mr-[9px]" v-for="(item,index) in gameList" :key="index+'edfr'" @click="startGame(item)">
                <div
                  class="flex-shrink-0 w-[90px] h-[90px] md:w-[196px] md:h-[244px] cursor-pointer"
                >
                  <div
                    class="v-progressive-image game-item h-full relative rounded-modal group bg-modal md:bg-modal v-progressive-image-object-cover"
                    data-empty="false"
                  >
                    <div style="padding-bottom: 100%">
                      <img
                        class="v-progressive-image-main"
                        :src="item.gameLogoPic"
                        :alt="item.gameTitle"
                      />
                 
                    </div>
                  </div>
                </div>

                <div class="mt-2 text-center content-area overflow-ellipsis overflow-hidden text-sm">
                  {{item.gameTitle}}
                </div>
              </div>
            </div>
          </div>
          </Tag>
        <Tag v-if="0"  title="Platform"/>
        <Tag v-if="0" title="Tag"/>
        <Tag  :title="$t('common.favourite')">
            <div
            class="flex w-full py-[15px] overflow-x-auto overflow-hidden scroll-smooth"
          >
            <div class="flex">
              <div class="flex flex-col items-center last:mr-0 mr-[9px]" v-for="(item,index) in allGame" :key="index+'sadfff'" @click="startGame(item)">
                <div
                  class="flex-shrink-0 w-[90px] h-[90px] md:w-[196px] md:h-[244px] cursor-pointer"
                >
                  <div
                    class="v-progressive-image game-item h-full relative rounded-modal group bg-modal md:bg-modal v-progressive-image-object-cover"
                    data-empty="false"
                  >
                    <div style="padding-bottom: 100%">
                      <!-- src="https://cdn.hg66sdt65nfx64.com/upload-images/platform-game/material-package-two/PP_SLOT_$$vs3train$$_icon.webp" -->
                      <img
                      class="v-progressive-image-main"
                      :src="item.gameLogoPic"
                        :alt="item.gameTitle"
                      />
                      <!-- <div class="v-progressive-image-slot-default">
                        <div
                          class="text-[#fff] flex flex-col h-full justify-end pb-[5px] md:pb-2.5 px-[5px] text-center"
                        ></div>
                        <div
                          class="absolute bg-transparent left-0 top-0 md:group-hover:bg-black/60 w-full h-full duration-base rounded-normal flex items-start"
                        >
                          <img
                            src="https://sky-win.cc/img/to-game-b5bb4c59.svg"
                            alt="to-game"
                            class="h-14 w-14 m-auto duration-base opacity-0 scale-50 md:group-hover:opacity-100 md:group-hover:scale-100"
                          />
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div class="mt-2 text-center content-area overflow-ellipsis overflow-hidden text-sm">
                  {{item.gameTitle}}
                </div>
              </div>
            </div>
          </div>
      </Tag>

      </div>
    </div>
  </div>
</template>
<script>
import Tag from './tag.vue'
export default {
    components:{
        Tag
    },
  data() {
    return {
      value: "",
      query:{
        gameProvider: [],
        gameTitle: "",
        gameType: "",
        tag:[],
        pageNum:1,
        pageSize:100,
      },
      gameList:[],
      allGame:[]
    };
  },
  watch:{
    value(v){
      this.query.gameTitle = v
      this.getList()
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      this.$client.getzongheList(this.query).then((res) => {
        if (res) {
          this.gameList = res;
        }
        if(this.allGame.length===0){
          this.allGame = res.length>10 ?res.slice(0,10):res
        }
      });
    },
    startGame(e){
      this.$client.GetGameInfo(e.gameId).then(res=> {
        this.$store.dispatch("app/toggleSearch", false);
        this.$router.push({path: '/playconfirm', query: {id: JSON.stringify(e)}})
      })
    }
  }
};
</script>
