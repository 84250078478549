export function randomColor() {
  return "#" + (~~(Math.random() * (1 << 24))).toString(16);
}
export function genUid() {
  return new Date().getTime() + "" + Math.floor(Math.random() * 899 + 100);
}

export function getQueryString() {
  var url = location.hash; // 获取url中"?"符后的字串
  var theRequest = {};
  // if (url.indexOf('?') !== -1) {
  //   const str = url.split('?')[1]
  //   const strs = str.split('&')
  //   for (var i = 0; i < strs.length; i++) {
  //     theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
  //   }
  // }
  var key1 = "room=";
  var key2 = "&im=";
  var key3 = "&name=";
  var key4 = "&role=";
  if (url.indexOf(key4) === -1) {
    if (
      url.indexOf(key1) === -1 ||
      url.indexOf(key3) === -1 ||
      url.indexOf(key3) === -1
    ) {
      return {};
    }
    theRequest.room = unescape(
      url.substring(url.indexOf(key1) + 5, url.indexOf(key2))
    );
    theRequest.im = unescape(
      url.substring(url.indexOf(key2) + 4, url.indexOf(key3))
    );
    theRequest.name = unescape(
      url.substring(url.indexOf(key3) + 6, url.length)
    );
    return theRequest;
  } else {
    if (
      url.indexOf(key1) === -1 ||
      url.indexOf(key3) === -1 ||
      url.indexOf(key3) === -1 ||
      url.indexOf(key4) === -1
    ) {
      return {};
    }
    theRequest.room = unescape(
      url.substring(url.indexOf(key1) + 5, url.indexOf(key2))
    );
    theRequest.im = unescape(
      url.substring(url.indexOf(key2) + 4, url.indexOf(key3))
    );
    theRequest.name = unescape(
      url.substring(url.indexOf(key3) + 6, url.indexOf(key4))
    );
    theRequest.role = unescape(
      url.substring(url.indexOf(key4) + 6, url.length)
    );

    return theRequest;
  }
}

export const formatNewDate = function (date, isAll = true) {
  if (date === "" || date === undefined) return "";
  if (typeof date === "number" || typeof date === "string")
    date = new Date(Number(date));
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  var d = date.getDate();
  var h = date.getHours();
  var m1 = date.getMinutes();
  var s = date.getSeconds();
  m = m < 10 ? "0" + m : m;
  d = d < 10 ? "0" + d : d;
  h = h < 10 ? "0" + h : h;
  m1 = m1 < 10 ? "0" + m1 : m1;
  s = s < 10 ? "0" + s : s;
  if (isAll) {
    return y + "-" + m + "-" + d + " " + h + ":" + m1 + ":" + s;
  } else {
    return y + "-" + m + "-" + d;
  }
};

export function MesType(type) {
  var json = {
    "RC:TxtMsg": "文本类型消息",
    "RC:ImgMsg": "图片类型消息",
    "RC:HQVCMsg": "声音类型消息",
    "RC:SightMsg": "视频类型消息",
    "RC:FileMsg": "文件类型消息",
  };
  return json[type] || "未知类型";
}
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

export function analyseQuery(url) {
  var theRequest = {};

  if (url.indexOf("?") !== -1) {
    const str = url.split("?")[1];
    const strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}
export function back() {
  window.history.back();
}

export function openWindow(e) {
  window.open(e);
}
//全屏封装
export function launchIntoFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}
//退出全屏封装
export function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}
