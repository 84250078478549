import { asyncRoutes, constantRoutes } from '@/router'

const state = {
  routes: constantRoutes,
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, flag) {
    return new Promise(resolve => {
      let accessedRoutes
      if (!flag) {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = []
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
