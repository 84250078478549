import { createApp } from "vue";
import { mapGetters } from "vuex";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import client from "./api/client";
import ElementPlus from "element-plus";
import "vant/lib/index.css";

import "element-plus/dist/index.css";
import "@/styles/tailwindcss.css"; // global css
import "@/styles/index.scss"; // global css
import VueLazyload from 'vue-lazyload';

import "./permission"; // permission control
import { EventBus } from "@/util/bus";
import VueI18n from "./language/index";
import * as utils from "./util";
import {
  Button,
  Image as VanImage,
  PullRefresh,
  Popup,
  Dialog,
  Tabbar,
  TabbarItem,
  Sticky,
  Search,
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
  Field,
  CellGroup,
  Swipe,
  SwipeItem,
  NoticeBar,
  Tab,
  Tabs,
  NavBar,
  Icon,
  Cell,
  List,
  Notify,
} from "vant";
const vm = createApp(App);
vm.use(Notify);
vm.use(List);
vm.use(Cell);
vm.use(Icon);
vm.use(Field);
vm.use(CellGroup);
vm.use(RadioGroup);
vm.use(Radio);
vm.use(VanImage);
vm.use(Button);
vm.use(ElementPlus);
vm.use(PullRefresh);
vm.use(Popup);
vm.use(Tabbar);
vm.use(TabbarItem);
vm.use(Sticky);
vm.use(Swipe);
vm.use(SwipeItem);
vm.use(NoticeBar);
vm.use(Search);
vm.use(Tab);
vm.use(Tabs);
vm.use(NavBar);
vm.use(Collapse);
vm.use(CollapseItem);
vm.use(VueLazyload)
// 配置项
vm.use(VueLazyload, {
  preLoad: 1.3,
  error: './assets/logo.png',
  loading: './assets/logo.png',
  attempt: 2
})
const componentsContext = require.context(
  "./auto_reg_com",
  true,
  /index.(vue|js)$/
);
componentsContext.keys().forEach((file_name) => {
  const name = file_name.replace("./", "").replace(/\/index.(vue|js)$/, "");
  const componentConfig = componentsContext(file_name).default;
  vm.component(name, componentConfig); //组件全局注册： Vue.component(注册的名称,vue组件)
});

vm.config.globalProperties.$Dialog = Dialog;
vm.config.globalProperties.$Notify = Notify;

vm.config.globalProperties.$client = client;
vm.config.globalProperties.$eventbus = EventBus;
vm.config.globalProperties.$utils = utils;

vm.config.globalProperties.$rongCloud = process.env.VUE_APP_rongCloud;
vm.config.globalProperties.$url = process.env.VUE_APP_url;
vm.mixin({
  computed: {
    ...mapGetters(["device", "appInfo", "userInfo", "isLogin"]),
  },
});
vm.use(store).use(router).use(VueI18n).mount("#app");
