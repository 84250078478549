<template>
    <div>
    <div class="text-mobile-title text-second-layout-tx md:text-pc-title">
          {{title}}
        </div>
    </div>
</template>
<script>
export default{
    props:{
        title:{
            type:String
        }
    }
}
</script>