const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  search: (state) => state.app.search,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  permission_routes: (state) => state.permission.routes,
  errorLogs: (state) => state.errorLog.logs,
  userInfo: (state) => state.user.userInfo,
  gameInfo(state) {
    return state.user.gameInfo;
  },
  appInfo(state) {
    return state.user.appInfo;
  },
  isLogin(state) {
    return Object.keys(state.user.userInfo).length > 0;
  },
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  introduction: (state) => state.user.introduction,
  roles: (state) => state.user.roles,
};
export default getters;
