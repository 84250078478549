import { createI18n } from "vue-i18n"; // 引入vue-i18n组件
const i18n = createI18n({
  locale: "en", // 默认显示的语言
  messages: {
    zh: require("./language-ch.js").default, // 引入语言文件
    en: require("./language-en.js").default,
    hi: require("./language-en.js").default,
    th: require("./language-en.js").default,
    ms: require("./language-en.js").default,
  },
});
export default i18n;
