export default {
  common: {
    confirm: "Confirm",
    apply: "Submit",
    pleaseInput: "Please type in {N}",
    totalsome: "Total {N}",
    norecord: "No Records",
    game: "GAME",
    keywords: "KeyWords",
    favourite: "Guess what you like",
    select: "Chose {N}",
    switch: "Switch {N}",
    wallet: "Wallet",
    lastDays: "{N}days",
    billTypeStr: "Bet",
  },
  navs: {
    game: "Game Center",
    slot: "Game Center",
    search: "Search",
    gameRecord: "Game Records",
  },
  game: {
    gameNavs: "Game category",
    gameElectronic: "Slot",
    gameElectronicDesc:
      "Slot games are available in various styles for your selection",
    gameVideo: "Live",
    gameTicket: "Fishing",
    gameChess: "Chess&Card",
    gameSport: "Sport",
    blockchain: "BlockChain Game",
    gameRecommand: "Recommendations",
    gameNew: "New releases",
    gameRecentBet: "Recent bets",
    gameMonthList: "Top charts",
    gameName: "Game Name",
    gameUser: "Player",
    gametime: "Time",
    gameBet: "Bet",
    gameRate: "Odds",
    gameWin: "Win",
  },
  playConfirm: {
    gameBlance: "Game Balance",
    recharge: "Recharge",
    transferIn: "TransferIn",
    transferOut: "TransferOut",
    start: "Start the game",
    avaliableBlance: "Game Balance",
    diamondBalance: "Diamond Balance",
    usdtRate: "USDT Exchange Rate",
    avaliableWithdrawBlance: "Avaliable Witdhraw Balance",
    supportBrowser: "Supported Browser",
    supportPlatform: "Supported Platform",
    supportLanguage: "Supported Language",
    transferInUsdt: "Transfer->USDT",
    transferOutUsdt: "USDT->Diamond",
    demo: "DEMO"
  },
  gameList: {
    filter: "Filter",
    tag: "Tags",
    provider: "Providers",
  },
  gameRecord: {
    order: "TradeNo",
    winAmount: "Win",
    betAmount: "Bet",
  },
};
