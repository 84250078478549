import request from "@/util/axios";
function analyseQuery(data) {
  var str = "?";
  for (const key in data) {
    str += key + "=" + data[key] + "&";
  }
  return str.substr(0, str.length - 1);
}
const CHAT = {
  appInfo() {
    return request({
      url: "/cf/app/info",
      method: "get",
    });
  },
  login(data) {
    return request({
      url: "/cf/user/login" + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  logout() {
    return request({
      url: "/cf/user/logout",
      method: "post",
    });
  },
  userinfo() {
    return request({
      url: "/cf/user/info",
      method: "get",
    });
  },
  getImToken() {
    return request({
      url: "/cf/rongcloud/token",
      method: "get",
    });
  },
  // index
  // 获取直播地址
  getLive() {
    return request({
      url: "/cf/game/live",
      method: "get",
    });
  },
  // 获取比赛信息
  getGameInfo() {
    return request({
      url: "/cf/game/info",
      method: "get",
    });
  },
  // 投注
  gameBet(data) {
    return request({
      url: "/cf/game/bet" + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  // 获取最近比赛结果生成图表
  getLastGameList() {
    return request({
      url: "/cf/game/task/list",
      method: "get",
    });
  },
  // 分页加载比赛结果
  getLastGameHistory(data) {
    return request({
      url: "/cf/game/task/history" + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  // 获取投注记录
  userBetHistory(data = { pageNum: 1, pageSize: 20 }) {
    return request({
      url: "/cf/user/bet/history" + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  // 获取投注金额列表
  getGameFastBetAmount() {
    return request({
      url: "/cf/game/bet-fast-amount",
      method: "get",
    });
  },
  // 撤回消息
  deleteMsg(data) {
    return request({
      url: "/cf/rongcloud/message/recall",
      method: "post",
      data,
    });
  },
  getBanner(data) {
    return request({
      url: "/cf/event/banner" + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  getDailyLogin() {
    return request({
      url: "/cf/event/daily-login/list",
      method: "get",
    });
  },
  postDailyLogin() {
    return request({
      url: "/cf/event/daily-login/sign",
      method: "post",
    });
  },
  getDailyBetting() {
    return request({
      url: "/cf/event/daily-betting",
      method: "get",
    });
  },
  getDownLine() {
    return request({
      url: "/cf/event/down-line",
      method: "get",
    });
  },
  getFirstDeposit() {
    return request({
      url: "/cf/event/fist-deposit",
      method: "get",
    });
  },
  // 获取投注记录
  usertransaction(q, data = { pageNum: 1, pageSize: 20 }) {
    return request({
      url: `/cf/user/${q}/transaction/list` + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  deposithistory(data = { pageNum: 1, pageSize: 20 }) {
    return request({
      url: `/cf/user/deposit/history` + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  // 提现记录
  withdrawhistory(data = { pageNum: 1, pageSize: 20 }) {
    return request({
      url: `/cf/user/withdraw/history` + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  getsummery() {
    return request({
      url: "/cf/agent/summery",
      method: "get",
    });
  },
  // 提现记录
  summaryhistory(data = { pageNum: 1, pageSize: 20 }) {
    return request({
      url: `/cf/agent/downline/summary` + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  // 领取工资
  reciveSalary() {
    return request({
      url: `/cf/agent/salary/grab`,
      method: "post",
    });
  },
  // 领取记录
  salaryHistory(data = { pageNum: 1, pageSize: 20 }) {
    return request({
      url: "/cf/agent/salary/history" + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  getfastamountlist() {
    return request({
      url: "/cf/pay/deposit/fast-amount-list",
      method: "get",
    });
  },
  chongzhiapply(data) {
    return request({
      url: "/cf/pay/deposit/apply" + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  applyWithdraw(data) {
    return request({
      url: "/cf/pay/withdraw/apply" + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  getbankcardlist() {
    return request({
      url: "/cf/user/bankcard/list",
      method: "get",
    });
  },
  getwithdrawinfo() {
    return request({
      url: "/cf/pay/withdraw/info",
      method: "get",
    });
  },
  addBank(data) {
    return request({
      url: "/cf/user/bankcard/add" + `${analyseQuery(data)}`,
      method: "post",
      data,
    });
  },
  addUpiBankcard(data) {
    return request({
      url: "/cf/user/upi/add" + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  getBankList() {
    return request({
      url: "/cf/user/bank/name/list",
      method: "post",
    });
  },
  getVipInfo() {
    return request({
      url: "/cf/vip/summery",
      method: "get",
    });
  },
  getDaliySalay() {
    return request({
      url: "/cf/vip/salary/dialy/grab",
      method: "post",
    });
  },
  getMonthSalay() {
    return request({
      url: "/cf/vip/salary/month/grab",
      method: "post",
    });
  },
  getLevelAward() {
    return request({
      url: "/cf/vip/levelup/reward/grab",
      method: "post",
    });
  },
  // 获取验证码
  smsforgetpwd(data) {
    return request({
      url: "/cf/user/forget-pwd/sms" + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  // 重置密码
  forgetpwdreset(data) {
    return request({
      url: "/cf/user/forget-pwd/reset" + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  // 注册
  register(data) {
    return request({
      url: "/cf/user/register" + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  // 获取注册验证码
  smsregister(data) {
    return request({
      url: "/cf/user/register/sms" + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  // 获取站内信列表
  getMessages(data) {
    return request({
      url: "/cf/user/message/list" + `${analyseQuery(data)}`,
      method: "get",
    });
  },
  // 读取站内信
  readMessage(data) {
    return request({
      url: `/cf/user/message/${data}/read`,
      method: "get",
    });
  },
  // 获取站内信详细信息
  getMessage(data) {
    return request({
      url: `/cf/user/message/${data}/detail`,
      method: "get",
    });
  },
  suggest(data) {
    return request({
      url: `/cf/user/suggest` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  diamondTransfer(data) {
    return request({
      url: `/cf/pay/diamond/transfer-out` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  getzongheList(data) {
    return request({
      url: `/cf/zonghe/game/list` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  gameLunch(data) {
    return request({
      url: `/cf/zonghe/game/lunch` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  zongheHomeInfo() {
    return request({
      url: `/cf/zonghe/home/info`,
      method: "post",
    });
  },
  queryOption(data) {
    return request({
      url: `/cf/zonghe/game/query-option` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  getGameBlance(data) {
    return request({
      url: `/cf/zonghe/game/balance` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  transferIn(data) {
    return request({
      url: `/cf/zonghe/game/transfer/in` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  transferOut(data) {
    return request({
      url: `/cf/zonghe/game/transfer/out` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
  sendGameMessage(data) {
    return request({
      url: `/cf/zonghe/game/message/send?message=` + encodeURIComponent(data),
      method: "post",
    });
  },
  GetWalletList() {
    return request({
      url: `/cf/zonghe/game/wallet`,
      method: "post",
    });
  },
  GetGameInfo(gameId) {
    return request({
      url: `/cf/zonghe/game/${gameId}/info`,
      method: "post",
    });
  },
  getGameRecords(data) {
    return request({
      url: `/cf/zonghe/game/records` + `${analyseQuery(data)}`,
      method: "post",
    });
  },
};
export default CHAT;
