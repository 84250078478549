import axios from "axios";
import { getToken, removeToken } from "@/util/auth";
// import router from "@/router";
// import { ElMessage } from "element-plus";
import { Notify } from "vant";
import md5 from "blueimp-md5";
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_url, // api的base_url
  timeout: 30000, // 请求超时时间
});

// request拦截器
// let loadingInstance
service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    const token = getToken();
    if (token === null || token === "") {
      // delete config.headers.Authorization
    } else {
      config.headers["Authorization"] = token;
    }
    if (localStorage.getItem("lang") === "zh") {
      config.headers["lang"] = "zh_CN";
    } else {
      config.headers["lang"] = "en_US";
    }
    const r = new Date().getTime();
    const mds = md5(r + "FRESHZNEzLDZh031sO8lOyzwH7CX7C7j75o");
    config.headers["key"] = mds;
    config.headers["r"] = r;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    let res;
    const { data } = response;
    if (data) {
      switch (data.errorCode) {
        case "200":
          res = data.resultData;
          break;
        case "":
          if (data.errorMessage) {
            Notify({
              type: "danger",
              message: data.errorMessage,
              duration: 6000,
            });
          }
          return;
        default:
          if (data.errorMessage) {
            Notify({
              type: "danger",
              message: data.errorMessage,
              duration: 6000,
            });
          }
          return;
      }
    }
    return res;
  },
  (error) => {
    if (String(error).indexOf("401") > -1) {
      // removeToken();
      // router.push("/login");
      // Notify({ type: "danger", message: "Login TimeOut", duration: 6000 });
    }
    // ElMessage({
    //   message: error,
    //   type: 'error'
    // })
    return;
  }
);
export default service;
