<template>
  <div style="padding: 0 0 0 1rem;" @click="toggleClick" class="flex items-center">
    <svg
      v-if="!isMobile"
      :class="{'is-active':isActive}"
      class="hamburger"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
    >
      <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
    </svg>
    <!-- <van-icon v-else size="18" name="https://lanhu.oss-cn-beijing.aliyuncs.com/ps5kq7mls32ahd1eqhgesae4tc0gbv71ioa455c77e-6e3a-4da6-9e74-1c4ff2ee5149"></van-icon> -->
    <van-icon v-else size="24" :name="currentpage==='home'?require('../../assets/logo.png'):'arrow-left'" color="#fff"></van-icon> 
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isMobile:{
      type:Boolean,
      default:false
    },
  },
  data(){
    return{
      currentpage:''
    }
  },
  watch: {
    $route:{
      immediate:true,
      handler:function(route) {
        this.currentpage = ''
        if (route.path==='/'||route.path==='/game') {
          this.currentpage = 'home'
          return
        }
      }

    }
  },
  methods: {
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  fill:#fff;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
</style>
