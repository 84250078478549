<template>
  <div class="flex justify-between w-full px-1 bg-transparent header-contianer">

    <div class="flex items-center header-contianer-left-scroll ">
    </div>
    <div class="header-contianer-right-scroll">
      <van-image :src="require('../../../assets/v5/search.svg')" class="w-[20px]  mr-[15px]" @click="toggleSearch" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/util/bus";
export default {
  components: {

  },
  data() {
    return {
      moneyIcon: require("@/assets/icon/home_icon_qianbao@2x.png"),
      vIcon: require("@/assets/icon/home_icon_vip@2x.png"),
      userIcon: require("@/assets/icon/me_profile_icon.png"),
      dialogVisible: false,
      chongzhidialogVisible: false,
      tixiandialogVisible: false,
      zuanzhangdialogVisible: false,
      vipdialogVisible: false,
      bankdialogVisible: false,
      loginForm: {
        phoneNumber: "",
        phoneAreaCode: "",
        userPassword: "",
      },
      isloading: false,
      languages: [
        { label: "中文", value: "zh" },
        { label: "EN", value: "en" },
        // { label: 'हिंदी', value: 'hi' },
        // { label: 'ไทย', value: 'th' },
        // { label: 'Bahasa Melayu', value: 'ms' }
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "device", "isLogin", "appInfo"]),
    tipInfo() {
      if (this.appInfo.marqueeList) {
        return this.appInfo.marqueeList.join(
          `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`
        );
      } else {
        return "***********No MARQUEES**************";
      }
    },
  },
  watch: {
    isLogin: {
      handler: function (val) {
        if (val) {
          this.getImtoken();
          this.$store.dispatch("user/getInfo");
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$eventbus.on("login", () => {
      // this.dialogVisible = true
      this.$router.push("/login");
    });
  },
  mounted() { },
  methods: {
    changeLan(e) {
      localStorage.setItem("lang", e);
    },
    sub(val) {
      if (String(val).length > 5) {
        return String(val).substr(0, 3) + "...";
      }
      return val;
    },
    getImtoken() {
      this.$client.getImToken().then((res) => {
        // console.log('imtoken', res)
        sessionStorage.setItem("roomId", res.roomId);
        sessionStorage.setItem("imtoken", res.token);
        EventBus.emit("loginSuc");
      });
    },
    async logout() {
      this.$Dialog
        .confirm({
          message: this.$t("index.logoutTip"),
          confirmButtonText: "OK",
          cancelButtonText: "CANCEL",
        })
        .then(() => {
          this.$store.dispatch("user/logout").then((res) => {
            // this.$router.push('/login')
          });
        })
        .catch(() => {
          // on cancel
        });
    },

    handleClose(done) {
      this.vipdialogVisible = false;
      this.chongzhidialogVisible = false;
      this.tixiandialogVisible = false;
      this.zuanzhangdialogVisible = false;
    },
    open(e) {
      this.$router.push(e)
    },
    handleClosechongzhi(done) {
      this.dialogVisible = false;
      done();
    },
    handleClosetixian(done) {
      this.dialogVisible = false;
      done();
    },
    toggleSearch() {
      this.$store.dispatch("app/toggleSearch",true);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header-contianer {
  height: 100%;

  .header-contianer-left-scroll {}

  .header-contianer-right-scroll {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    cursor: pointer;

    .money {
      margin-left: 0.2rem;
      font-size: 0.5rem;
    }

    .username {
      margin: 0 0.2rem;
    }

    .login {
      margin-left: 10px;
      margin-right: 20px;

      &:hover {
        color: #409eff;
      }
    }

    .logout {
      margin: 0 10px;

      &:hover {
        color: #409eff;
      }
    }
  }
}

@keyframes wordsLoop {
  0% {
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
  }

  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@-webkit-keyframes wordsLoop {
  0% {
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
  }

  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
</style>
