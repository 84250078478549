<template>
  <div class="item-container p-6 m-4 rounded-xl bg-[#f1f1f1] text-[#000]">
    <div class="header">
      <slot name="title">
        <span class="title">
          {{ title }}
        </span>
      </slot>
      <slot name="subtitle">
        <span class="subtitle text-base">{{ subtitle }}</span>
      </slot>
    </div>
    <slot name="content" />
    <div class=" text-base">{{ time }}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: function() {
        return ''
      }
    },
    subtitle: {
      type: String,
      default: function() {
        return ''
      }
    },
    time: {
      type: String,
      default: function() {
        return ''
      }
    }
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.item-container{
    display: flex;
    flex-direction: column;
    text-align: left;
    .header{
      display: flex;
      justify-content: space-between;
      .title{
          font-size: 1rem;
          font-weight: bold;
      }
      
  }

}
</style>
