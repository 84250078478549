<template>
  <div>
    <div class="rounded-2xl relative  border-solid border border-[#e9890c]" >
      <div class="cursor-pointer balance-selector ">
        <div @click="select"
          class="h-[30px] break-word border-black flex bg-input-default relative px-[10px] cursor-pointer rounded-normal items-center border border-solid border-transparent">
          <div class="selected-field flex items-center">
            <img class="w-5 h-5 mr-2.5 " src="../../assets/v5/diamone_coin.png" />
            <span class="ml-auto mr-[18px]">{{ title }}</span>
          </div>
          <img
            class="w-4 h-4  ml-auto ease-in duration-base transition-transform mr-[25px]"
            alt="dropwdown-arrow" data-src="../../assets/v5/arrow-down-cbd9578f.svg"
            src="../../assets/v5/arrow-down-cbd9578f.svg" lazy="loaded" />
          <div v-if="isrefresh"
            class="absolute top-0 right-0 flex items-center justify-center h-full p-[5px_5px] ">
            <img src="../../assets/v5/platform-refresh-f5d5d242.svg" alt="refresh" class="w-5 h-5"
              @click.stop="refresh" />
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="show" position="bottom" :style="{ height: '30%' }">
      <slot name="popup"></slot>
    </van-popup>
  </div>
</template>
<script >

export default {
  data() {
    return {
      show: false
    }
  },
  props: {
    isrefresh: {
      type: Boolean,
      default: true
    },
    title: {
      type: [String,Number],
      default: ''
    },
    icon: {
      type: String,
      default: 'https://cdn.hg66sdt65nfx64.com/0/uploadFiles/currency/CNY.svg'
    }
  },

  methods: {

    select() {
      // this.show = true;
      this.$emit('select')
      console.log('select')
    },
    close() {
      this.show = false;

    },
    refresh() {
      console.log('refresh')
      this.$emit('refresh')

    }
  }
}

</script>

