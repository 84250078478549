import router from "./router";
var whiteList = [
  "/login",
  "/signup",
  "/forget",
  "/slot",
  "/game",
  "/play",
  "/playconfirm",
  "/turntable",
  "/game_record",
];
import { getToken } from "@/util/auth";
router.beforeEach(async (to, from, next) => {
  // console.log("to:", to);
  const token = getToken();
  localStorage.setItem(
    "currentPath",
    JSON.stringify({
      path: to.fullPath,
      time: new Date().getTime(),
    })
  );
  if (token) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    /* has no token*/
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});

router.afterEach(() => {
  // finish progress bar
});
