<template>
          <div 
        class="flex flex-col items-center justify-center max-w-[1230px] h-[200px] md:h-[313px] m-auto"
      >
      <img
              src="../../assets/v5/no-game-2a506ace.svg"
              alt="no game"
              class="w-20 h-20 md:w-[120px] md:h-[120px]"
              draggable="false"
          />
        <slot>
            <div class="text-white">{{content}}</div>
        </slot>
      </div>
    </template>
    <script>
    export default{
        name:'noData',
        props:{
            content:{
                type:String,
                default:'没有符合的结果'
            }
        }
    }
    </script>