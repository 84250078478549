import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/layout";
export const asyncRoutes = [];
export const constantRoutes = [
  // 娱乐首页
  {
    path: "/",
    component: Layout,
    redirect: "/game",
    children: [
      {
        path: "/game",
        name: "game",
        meta: { title: "navs.game", icon: "home", affix: true },
        component: () => import("@/views/game/index.vue"),
      },
    ],
  },
  {
    path: "/slot",
    component: Layout,
    children: [
      {
        path: "/slot",
        name: "slot",
        meta: { title: "navs.slot", icon: "home", affix: true },
        component: () => import("@/views/gameList/index.vue"),
      },
    ],
  },
  // 敬请期待
  {
    path: "/wait",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/wait",
        name: "wait",
        meta: { title: "navs.wait", icon: "home", affix: true },
        component: () => import("@/views/wait/index.vue"),
      },
    ],
  },
  // 开始确认
  {
    path: "/playconfirm",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/playconfirm",
        name: "playconfirm",
        meta: { title: "navs.slot", icon: "home", affix: true },
        component: () => import("@/views/play_confirm/index.vue"),
      },
    ],
  },
  // 游戏界面

  {
    path: "/play",
    hidden: true,
    component: () => import("@/views/play/index.vue"),
  },
  // 游戏记录
  {
    path: "/game_record",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "",
        hidden: true,
        meta: { title: "navs.gameRecord", icon: "home", affix: true },
        component: () => import("@/views/game_record/index.vue"),
      },
    ],
  },
  // 游戏界面
  {
    path: "/turntable",
    hidden: true,
    component: () => import("@/views/turn_table/index.vue"),
  },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

export default router;
