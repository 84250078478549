<template>
    <div>
        <button class=" text-white w-full py-2.5 px-[15px] rounded-xl flex justify-center items-center  duration-300 transition-colors  hover:bg-btn-primary-hover  border-solid border " 
        :style="{'background-color':bg,'color':color}">
            <slot></slot>
        </button>
    </div>
</template>
<script>
export default{
    props:{
        bg:{
            type:String,
            default:()=>{
                return '#FF9443'
            }
        },
        color:{
            type:String,
            default:()=>{
                return '#fff'
            }
        }
        
    },

}
</script>