<template>
  <div v-if="device === 'mobile'">
    <div class="w-full  text-[#333] text-lg">
      <div class="profile flex relative flex-col h-full">
        <van-sticky :offset-top="0">
          <div class=" head">
            <div class="profile-banner  w-full mb-6">
              <div class="flex justify-end">
                <van-icon name="cross" size="30px" class=" m-4" @click="toggleSideBar" />
              </div>
              <div class="profile-banner-bottom flex items-center space-x-2.5 pt-4 pl-10 relative">
                <div class="relative flex items-center justify-center  overflow-hidden w-[70px] !h-[70px] "
                  id="profile-avatar">
                  <img v-if="isLogin" class="h-full w-full" src="https://sky-win.cc/img/demo-c68e0ecb.webp" alt="avatar" />
                  <van-icon size="80" v-else name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png" />
                </div>
                <div class="mb-0  relative" v-if="isLogin">
                  {{ userInfo.userNick }}
                </div>
                <div class="mb-0  relative text-[#2440b3] underline" v-else @click="go('/login')">
                  {{ $t('index.login') }}
                </div>
              </div>
            </div>
  
            <div class="profile-area bg-white m-6 p-4 mt-0" v-if="isLogin">
              <yuSelect ref="yuselect" :title="userValue" @refresh="refreshAccount" :icon="icon" class="bg-[#f0f0f0]">
                <template #popup>
                  <div @click="selectAccount('jinbi')"
                    class="flex flex-row justify-center items-center p-2 m-2 bg-[#f1f1f1]">
                    <img :src="moneyIcon">
                    <span>
                      Coin
                    </span>
                  </div>
                  <div @click="selectAccount('zuanshi')"
                    class="flex flex-row justify-center items-center p-2 m-2 bg-[#f1f1f1]">
                    <img :src="vIcon">
                    <span>
                      Diamond
                    </span>
                  </div>
                </template>
              </yuSelect>

              <div class="flex justify-around items-start md:space-x-1 mt-8 text-base">
                <div @click="go(item.url)" v-for="(item, index) in fastfunc" id="view_profile_state_deposit"
                  class="flex flex-col md:flex-row items-center flex-1 cursor-pointer space-y-2 md:space-x-2.5 md:space-y-0 md:p-[12px_15px] md:border md:border-layout-bd md:border-solid rounded-normal md:bg-[rgba(0,0,0,0.2)] md:hover:border-second-input-hover-bd">
                  <img class="h-[24px] w-[24px] mb-1 " :src="item.icon"
                    alt="state-deposit" />
                  <div class="text-ellipsis  overflow-hidden text-center w-[79px] leading-4">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-sticky>

        <div class="profile-area p-4 text-base pt-0">
          <div 
            class="profile-menu  w-full"
            style="margin-bottom: 60px;">
            <div   class="m-2 bg-white">
              <cell-item title="体育首页" @click="go('/wait')" :icon="iconUrl('tysy')"></cell-item>
              <cell-item title="娱乐城首页" @click="go('/game')" :icon="iconUrl('tyc')"></cell-item>
              <cell-item title="我的收藏" @click="go('/wait')" :icon="iconUrl('sc')"></cell-item>
              <cell-item title="游玩记录" @click="go('/wait')" :icon="iconUrl('youwan')"></cell-item>
              <cell-item title="热门优惠" @click="go('/wait')" :icon="iconUrl('youhui')"></cell-item>
            </div>
            <div class=" flex flex-row space-x-2 justify-center">
              <img v-for="(item,index) in midList" class="w-[48%]" :key="index" :src="item.icon">
              </div>
            <div  class="m-2 bg-white">
              <cell-item title="电子" @click="go('/trade')" :icon="iconUrl('dianzi')"></cell-item>
              <cell-item title="视讯" @click="go('/trade?active=1')" :icon="iconUrl('shixun')"></cell-item>
              <cell-item title="彩票" @click="go('/trade?active=2')" :icon="iconUrl('caipiao')"></cell-item>
              <cell-item title="棋牌" @click="go('/wait')" :icon="iconUrl('qipai')"></cell-item>
              <cell-item title="体育" @click="go('/betRecord')" :icon="iconUrl('tiyu')"></cell-item>
              <cell-item title="捕鱼" @click="go('/rechargeRecord')" :icon="iconUrl('yu')"></cell-item>
              <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item  name="1" accordion class="px-2">
                  <template #title>
                      <div class="flex flex-row items-center ml-2">

                        <img :src="iconUrl('duoyuyan')" class="w-[16px] h-[16px]">
                        <span class="ml-2">{{activeLan}}</span>
                      </div>
                    </template>
                  <div class="space-y-2 mx-4">
                    <item v-for="lan in languages"  @click="changeLan(lan)" other :icon="iconUrl('yu')" :title="lan.label" />
                  </div>
                </el-collapse-item>
              </el-collapse>

            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <div v-else :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" :collapse="isCollapse" :background-color="variables.menuBg"
        :text-color="variables.menuText" :unique-opened="false" :active-text-color="variables.menuActiveText"
        :collapse-transition="false" mode="vertical">
        <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import Item from './Item'

export default {
  components: { SidebarItem, Logo,Item },
  data() {
    const iconUrl = (name) => {
      return require(`@/assets/v4/xuandan/${name}.png`)
    }
    return {
      fastfunc: [
        {
          name: '充值',
          icon: iconUrl('chongzhi'),
          url: '/trade?active=0',
        },
        {
          name: '交易记录',
          icon: iconUrl('jiaoyi'),
          url: '/account_records',
        },
        {
          name: '刷新',
          icon: iconUrl('shuaxin'),
          url: 'reload',
        },
        // {
        //   name: '清理缓存',
        //   icon: iconUrl('qingli'),
        //   url: 'clear',
        // },
        {
          name: '退出登录',
          icon: iconUrl('tuichu'),
          url: 'logout',
        },
      ],
      active: 0,
      userValue: "",
      icon: require('@/assets/v3/index/jb.png'),
      moneyIcon: require('@/assets/v3/index/jb.png'),
      vIcon: require('@/assets/v3/index/zs.png'),
      iconUrl: iconUrl,
      midList:[
      {
          name: 'vip',
          icon:  require('@/assets/v4/xuandan/jsvip.png'),
          url: '/trade?active=1',
        },
        {
          name: 'yaoqing',
          icon: require('@/assets/v4/xuandan/yqhy.png'),
          url: '/trade?active=1',
        },
      
      ],
      activeNames:'',
      languages: [
        { label: '中文', value: 'zh' },
        { label: 'ENGLISH', value: 'en' }
        // { label: 'हिंदी', value: 'hi' },
        // { label: 'ไทย', value: 'th' },
        // { label: 'Bahasa Melayu', value: 'ms' }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeLan(){
      const i = this.languages.find((e)=>(e.value ===this.$i18n.locale))
        return i.label
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return true
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  watch: {

  },
  mounted() {
    setTimeout(() => {
      this.selectAccount('jinbi')
    }, 500);
  },
  methods: {
    changeLan(e) {
      this.$i18n.locale = e.value
      localStorage.setItem('lang', e.value)
      location.reload();
    },
    go(e) {
      if(e==='reload'){
        location.reload();
        return
      }
      if(e==='logout'){
        this.logout();
        return
      }
      this.$router.push(e)
      this.toggleSideBar()
    },
    async logout() {
      this.$confirm(this.$t('index.logoutTip'), '提示', {
        confirmButtonText: this.$t('index.confirm'),
        cancelButtonText: this.$t('index.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout').then(res => {
          this.$router.push('/game')
        })
      }).catch(() => {
      })
    },
    selectAccount(e) {
      if (e === 'jinbi') {
        this.userValue = this.userInfo.userBalance
        this.icon = this.moneyIcon
      } else {
        this.userValue = this.userInfo.diamondBalance
        this.icon = this.vIcon
      }
      // this.$refs.yuselect.close()
    },
    refreshAccount() { },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    handleChange(){}
  }
}
</script>
<style>
.head{
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/psew69f0ix1xcon8dghpdqfk2u2x32gfesbf7d3dd7-ab8f-4d46-afb3-ba2b5d1af739)
    100% no-repeat;
  background-size: 100% 100%;
}
</style>
